import React, { useContext, useState } from "react";
import { Checkbox, IconButton } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { IconDocumentText, IconScale } from "@paro.io/base-icons";
import { Text, Avatar } from "@paro.io/base-ui";
import { Spinner } from "react-bootstrap";
import { Auth0Context } from "../../contexts/Auth0Context";
import { useSnackbar } from "notistack";
import { CartContext } from "../CartContext";
import {
  addFindFreelancersToCartFn,
  removeFindFlFromCartFn,
} from "../../services/apiService";
import { Rating } from "@material-ui/lab";

const isRatingsEnabled = process.env.REACT_APP_RATINGS_ENABLED === "true";

function AiResultCard({ freelancer, onViewProfile, proposalId, onViewReview }) {
  const respScore = freelancer.scores?.find(
    (s) => s.label === "Responsiveness Score"
  )?.score;
  const { user } = useContext(Auth0Context);
  const { enqueueSnackbar } = useSnackbar();
  const [cart, setCart] = useContext(CartContext).cartContext;
  const [compareFls, setCompareFls] = useContext(CartContext).compareFlsContext;
  const [isAddCartLoading, setAddCartLoading] = useState(false);

  const onChangeHandler = async (freelancer) => {
    setAddCartLoading(true);
    if (!cart.some((c) => c.id === freelancer.id)) {
      await addFindFreelancersToCartFn({
        proposalId: +proposalId,
        userId: user.auth0UserId,
        findFreelancerIds: [freelancer.id],
      });
      setCart((cart) => [...cart, { ...freelancer, flID: freelancer.id }]);
      enqueueSnackbar(`Added ${freelancer.name}  to Cart!`, {
        variant: "success",
      });
    } else {
      await removeFindFlFromCartFn({
        proposalId: +proposalId,
        userId: user.auth0UserId,
        freelancerId: freelancer.id,
      });
      setCart((currentState) =>
        currentState.filter((c) => c.flID !== freelancer.id)
      );
      enqueueSnackbar(`Removed ${freelancer.name} from Cart!`, {
        variant: "warning",
      });
    }
    setAddCartLoading(false);
  };

  const onAddToCompare = (item) => {
    setCompareFls([...compareFls, item]);
  };

  const onRemoveCompare = (id) => {
    setCompareFls(compareFls.filter((i) => i.id !== id));
  };

  return (
    <div
      className="border border-success rounded-md p-3 my-3 bg-white"
      style={{ minWidth: "300px", maxHeight: "300px" }}
    >
      <div className="flex gap-4 items-center">
        <Avatar name="Avatar Name" size="md" src={freelancer.imageUrl} />
        <div className="flex flex-col gap-0">
          <div
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_PARO_APP_URL}/internal/expert-profile/${freelancer?.id}`,
                "_blank"
              );
            }}
            onMouseOver={() => {}}
          >
            <Text
              size="sm"
              className="p-0 font-bold m-0 cursor-pointer hover:underline"
            >
              {freelancer?.FL_Name}
            </Text>
          </div>
          <Text size="sm" className="p-0 text-xs">
            {freelancer.FL_Service}
          </Text>
        </div>
      </div>
      <Divider style={{ margin: "12px 0px", background: "#04E4B1" }} />
      <div>
        {isRatingsEnabled ? (
          <div
            className={`flex items-center gap-2 ${
              freelancer.overallRatingAvg > 0
                ? "cursor-pointer"
                : "cursor-not-allowed"
            }`}
            onClick={() => {
              if (freelancer.overallRatingAvg > 0) {
                onViewReview(freelancer);
              }
            }}
          >
            <Rating
              name="overallRatingAvg"
              defaultValue={freelancer.overallRatingAvg ?? 0}
              readOnly
              precision={0.1}
            />
            <Text>
              {freelancer.overallRatingAvg ?? 0}
              {freelancer.overallRatingAvg > 0 &&
                `(${freelancer.ratings.length})`}
            </Text>
          </div>
        ) : (
          <>
            {respScore > 6 ? (
              <Text size="sm" className="p-0 font-bold m-0 text-success">
                Very Likely to respond
              </Text>
            ) : respScore > 3 ? (
              <Text size="sm" className="p-0 font-bold m-0 text-warning">
                Likely to respond
              </Text>
            ) : (
              <Text size="sm" className="p-0 font-bold m-0 text-warning"></Text>
            )}
          </>
        )}

        <Text size="sm" className="p-0 font-bold m-0 text-danger-dark">
          ${freelancer.legacyMetadata?.hourlyRate} / hour
        </Text>
      </div>
      <Divider style={{ margin: "12px 0px", background: "#04E4B1" }} />
      <div className="flex justify-between items-center">
        <div className="flex justify-between items-center">
          {isAddCartLoading ? (
            <Spinner
              animation="grow"
              size="sm"
              className="text-success ml-3 mr-3.5"
            />
          ) : (
            <Checkbox
              checked={cart.some((c) => c.id === freelancer.id)}
              onChange={(e) => {
                onChangeHandler({
                  id: freelancer.id,
                  name: freelancer.FL_Name,
                  serviceLine: freelancer.FL_Service,
                });
              }}
              inputProps={{ "aria-label": "checkbox" }}
            />
          )}
          <div>
            <Text>Select</Text>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <IconButton onClick={() => onViewProfile(freelancer)}>
            <IconDocumentText className="text-grey" />
          </IconButton>
          <div className="flex items-center justify-center">
            <div
              onClick={() => {
                if (compareFls.some((i) => i.id === freelancer.id)) {
                  onRemoveCompare(freelancer.id);
                } else {
                  onAddToCompare(freelancer);
                }
              }}
              className={`p-1 rounded-sm cursor-pointer ${
                compareFls.some((c) => c.id === freelancer.id)
                  ? "bg-green-400"
                  : ""
              }`}
            >
              <IconScale />
            </div>
          </div>

          {/* {compareItems.includes(freelancer.id) ? (
            <Tooltip title="Remove from Compare">
              <IconButton onClick={() => onRemoveCompare(freelancer.id)}>
                <ShoppingCart className="text-success" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Add to Compare">
              <IconButton onClick={() => onAddToCompare(freelancer.id)}>
                <ShoppingCart />
              </IconButton>
            </Tooltip>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default AiResultCard;
