import { Box } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { CartContext } from "../components/CartContext";
import TopSection from "../components/TopSection";
import {
  BUYOUT_FLID,
  FAF_GROUPS,
  OPPORTUNITY_TYPES,
  getKeyByValue,
  isMM,
} from "../data/appConstants";
import {
  addFindFreelancersToCartFn,
  getFindFreelancersCartFn,
  getFreelancerProfilesByIdsFn,
  getProposal,
} from "../services/apiService";
import { Auth0Context } from "../contexts/Auth0Context";
import { useSnackbar } from "notistack";
import ParoError from "../components/ParoError";
import FreelancerInstantSearch from "../components/FindFreelancers/Index";
import { Button, Card } from "@paro.io/base-ui";
import CustomSpinner from "../components/CustomSpinner";

export default function FindFreelancers() {
  const [cart, setCart] = useContext(CartContext).cartContext;
  const [showCart, setShowCart] = useState(false);
  const [proposalDetails, setProposalDetails] = useState(null);
  const [loadingError, setLoadingError] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { proposalId } = useParams();
  const { user } = useContext(Auth0Context);
  const history = useHistory();

  const getCartFreelancers = useCallback(async () => {
    try {
      if (!cart.length) {
        const res = await getFindFreelancersCartFn(
          parseInt(proposalId),
          user.auth0UserId
        );
        const cartResponse = res.data.data.getFindFreelancersCart?.map(
          (f) => +f.freelancerId
        );
        if (cartResponse?.length > 0) {
          const response = await getFreelancerProfilesByIdsFn([
            ...new Set(cartResponse),
          ]);
          setCart(response.data.data.getFreelancerProfilesByIds || []);
          setShowCart(true);
        }
      } else {
        setShowCart(true);
      }
    } catch (err) {
      setLoadingError(true);
      enqueueSnackbar(`Unable to get cart details!`, {
        variant: "error",
      });
    }
  }, [cart.length, enqueueSnackbar, proposalId, setCart, user.auth0UserId]);

  useEffect(() => {
    const getProposalFn = async () => {
      try {
        const {
          data: {
            data: { getProposal: data },
          },
        } = await getProposal(parseInt(proposalId));

        if (isMM(data?.opportunityTypeMM)) {
          return history.push("/");
        }

        const formInputData = {
          status: "Available",
          search: [
            {
              title: data.serviceLine,
              inputValue: data.serviceLine,
              groupName: "Service Lines",
              required: true,
            },
            /*   ...data.matchmakingOptions.tags.map((t) => ({
            title: t.value,
            inputValue: t.value,
            groupName: getKeyByValue(FAF_GROUPS, t.field),
            required: t.required,
          })), */
            ...data.matchmakingOptions.tags.reduce((acc, t) => {
              if (
                (t.field === "industries" && t.value === "All") ||
                (t.field === "softwares" && t.value === "None")
              ) {
                return acc;
              } else {
                return [
                  ...acc,
                  {
                    title: t.value,
                    inputValue: t.value,
                    groupName: getKeyByValue(FAF_GROUPS, t.field),
                    required: t.required,
                  },
                ];
              }
            }, []),
          ],
          minHours: "" + data.matchmakingOptions.availabilityRange.min,
          maxHours: "" + data.matchmakingOptions.availabilityRange.max,
          minRate: "" + data.matchmakingOptions.rateRange.min,
          maxRate: "" + data.matchmakingOptions.rateRange.max,
          opportunityTypeMM: data.opportunityTypeMM,
        };
        setProposalDetails(formInputData);
      } catch (err) {
        setLoadingError(true);
      }
    };
    getProposalFn();
    getCartFreelancers();
  }, [getCartFreelancers, history, proposalId]);

  if (loadingError) {
    return <ParoError />;
  }

  if (!proposalDetails) {
    return (
      <>
        <TopSection />
        <CustomSpinner>Fetching Freelancers</CustomSpinner>
      </>
    );
  }

  return (
    <>
      <TopSection proposalId={proposalId} showCart={showCart} />

      {!!proposalDetails?.opportunityTypeMM && (
        <div className=" flex justify-end px-5 py-3">
          <span className="container text-right mx-auto rounded-lg font-medium ">
            Opportunity-Type: {proposalDetails?.opportunityTypeMM}
          </span>
        </div>
      )}
      {(proposalDetails?.opportunityTypeMM === OPPORTUNITY_TYPES.BuyOut ||
        proposalDetails?.opportunityTypeMM === OPPORTUNITY_TYPES.RaaS) && (
        <Box className="flex justify-center m-8 p-4">
          <Card className="w-1/2 py-4 pl-8 pr-4">
            {`This proposal will be directed to ${
              proposalDetails?.opportunityTypeMM === "RaaS"
                ? "RaaS"
                : "System Buyout"
            }. Do you confirm?`}
            <div className="flex my-3 justify-end gap-4">
              <span id="mm-editProposal-cancel">
                <Button
                  onClick={() => {
                    setCart([]);
                    history.push(`/`);
                  }}
                  label={`Cancel`}
                  type="button"
                  color="secondary"
                />
              </span>
              <span id="mm-editProposal-save">
                <Button
                  onClick={async () => {
                    setCart([
                      {
                        id: BUYOUT_FLID(),
                        name:
                          proposalDetails?.opportunityTypeMM === "RaaS"
                            ? "RaaS"
                            : "System Buyout",
                        flID: BUYOUT_FLID(),
                      },
                    ]);
                    await addFindFreelancersToCartFn({
                      proposalId: +proposalId,
                      userId: user.auth0UserId,
                      findFreelancerIds: [BUYOUT_FLID()],
                    });
                    history.push(`/proposal/${proposalId}/checkout`);
                  }}
                  label={`Confirm`}
                  type="button"
                  color="primary"
                />
              </span>
            </div>
          </Card>
        </Box>
      )}
      {proposalDetails?.opportunityTypeMM !== OPPORTUNITY_TYPES.BuyOut &&
        proposalDetails?.opportunityTypeMM !== OPPORTUNITY_TYPES.RaaS && (
          <Box className="mx-4 px-4">
            <FreelancerInstantSearch
              proposalId={proposalId}
              proposalTags={proposalDetails?.search}
            />
          </Box>
        )}
    </>
  );
}
