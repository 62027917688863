import axios from "axios";

let HOST_V2 = (process.env.REACT_APP_V2_ENDPOINT || "") + "/graphql";
let HOST_V3 = process.env.REACT_APP_V3_ENDPOINT || "";
let HOST_MM_V3 =
  (process.env.REACT_APP_MM_V3_ENDPOINT || "") + "/matchmaking-service/graphql";
// let API_URL = "";
// let BASE = "";
// HOST_V2 = process.env.REACT_APP_V2_ENDPOINT || "";
// API_URL = process.env.REACT_APP_API_ENV;
// BASE = HOST_V2; //`${HOST}/${API_URL}`;

export const getProposal = (proposalId) =>
  axios.post(`${HOST_V2}`, {
    operationName: "getProposal",
    query: `query getProposal($id: Int!){
      getProposal(id: $id){
        id
        name
        notes
        serviceLine
        secondaryServiceLine
        createDate
        description
        projectSize
        frequency
        billRateType
        onJobBoard
        opportunityType
        opportunityTypeMM
        pitchNotes
        salesforceOpportunityURL
        activeStatus
        pitchRequired
        matchmakingOptions {
          availabilityRange {
            min
            max
            __typename
          }
          rateRange {
            min
            max
            __typename
          }
          tags {
            field
            value
            required
            __typename
          }
          __typename
        }
        client {
          name
          id
          firmographics {    
            numberOfEmployees
            industry
            paroEquivalentClearbitIndustries
          }
          __typename
        }
        serviceLinesPlus
        requiredServiceLinesPlus
        estimatedBudget
        estimatedStartDate
        estimatedEndDate
        estimatedTerm
        questions {
          id
          question
        }
        __typename
      }
    }`,
    variables: { id: proposalId },
  });

export const getProposalMmData = (proposalId) =>
  axios.post(`${HOST_V2}`, {
    operationName: "getProposal",
    query: `query getProposal($id: Int!){
    getProposal(id: $id){
      id
      onJobBoard
      opportunityType
      opportunityTypeMM
    }
  }`,
    variables: { id: proposalId },
  });

export const getProposals = (salesRepId) =>
  axios.post(`${HOST_V2}`, {
    operationName: "getProposals",
    query: `query getProposals($salesRepId: Int!, $activeStatus: proposalActiveStatuses) {
      getProposals(salesRepId: $salesRepId, activeStatus: $activeStatus) {
        id
        name
        notes
        serviceLine
        secondaryServiceLine
        createDate
        description
        projectSize
        frequency
        billRateType
        onJobBoard
        opportunityType
        opportunityTypeMM
        pitchNotes
        salesforceOpportunityURL
        activeStatus
        pitchRequired
        matchmakingOptions {
          availabilityRange {
            min
            max
            __typename
          }
          rateRange {
            min
            max
            __typename
          }
          tags {
            field
            value
            required
            __typename
          }
          __typename
        }
        client {
          name
          id
          firmographics {    
            numberOfEmployees
            industry
            paroEquivalentClearbitIndustries
          }
          __typename
        }
        serviceLinesPlus
        requiredServiceLinesPlus
        estimatedBudget
        estimatedStartDate
        estimatedEndDate
        estimatedTerm
        questions {
          id
          question
        }
        __typename
      }
    }`,
    variables: { salesRepId: salesRepId, activeStatus: "ACTIVE" },
  });

export const getProposalFreelancers = (id) =>
  axios.post(`${HOST_V2}`, {
    operationName: "getProposalFreelancers",
    query: `query getProposalFreelancers($id: Int) {
      getProposalFreelancers(id: $id) {
        proposalId
        freelancerId
        freelancerName
        pitch
        proposedProjectStage
        proposedProjectStageUpdatedAt
        proposedProjectId
        workedWithClient
        flInterested
        score
        batchedEmailOrder
        __typename
      }
    }
    `,
    variables: { id: id },
  });

export const getFreelancers = ({ applicationStatus, tags }) =>
  axios.post(`${HOST_V2}`, {
    operationName: "getFreelancerProfiles",
    query: `query getFreelancerProfiles($queryOptions: FPQueryOptions) {
      getFreelancerProfiles(queryOptions: $queryOptions) {
        id
        name
        currentProjectedHours
        estimatedPotentialHours
        freelancerHours
        serviceLine
        state
        currentMonthClientCount
        previousMonthClientCount
        totalProposalClients
        currentMonthHoursCount
        previousMonthHoursCount
        availableHours
        clientBillRate
        imageUrl
        __typename
      }
    }
    `,
    variables: { queryOptions: { applicationStatus, tags } },
  });

export const getFreelancersOtherDetails = (freelancerIds) =>
  axios.post(`${HOST_V3}/graphql`, {
    operationName: "getFreelancersOtherDetails",
    query: `query getFreelancersOtherDetails($freelancerIds:[Int!]) {
      getFreelancersOtherDetails(freelancerIds: $freelancerIds) {
        userInfo {
          id
          email
          firstName
          imageUrl
          lastName
          phone
          __typename
        }
        freelancerCandidateNotes {
          id
          accountManager {
            id
            name
            email
            __typename
          }
          note
          timestamp
          stageId
          editable
          __typename
        }
        projects {
          projectScopeId
          id
          softwareTags {
            id
            value
            __typename
          }
          financialTags {
            id
            value
            __typename
          }
          name
          description
          startDate
          endDate
          frequency
          size {
            id
            value
            __typename
          }
          onsite
          client {
            id
            businessModel
            fundingSources
            companySize {
              id
              value
              __typename
            }
            industryTags {
              id
              value
              __typename
            }
            international
            annualRevenueRange {
              id
              value
              __typename
            }
            entityType {
              id
              value
              __typename
            }
            __typename
          }
          __typename
        }
        freelancerProfile {
          estimatedPotentialHours
          currentMonthClientCount
          currentMonthHoursCount
          currentMonthProjectCount
          previousMonthClientCount
          previousMonthHoursCount
          previousMonthProjectCount
          totalProposals
          totalProposalClients
          projectWinRate
          availableHours
          id
          name
          currentProjectedHours
          freelancerHours
          serviceLine
          state
          clientBillRate
          __typename
         }
      }
    }
    `,
    variables: { freelancerIds: freelancerIds },
  });

export const updateProposal = (id, data) =>
  axios.post(`${HOST_V2}`, {
    operationName: "amUpdateProposal",
    query: `mutation amUpdateProposal($id: Int!, $input: updateProposalInput) {
              updateProposal(id: $id, input: $input) {
                name
                notes
                description
                billRateType
                secondaryServiceLine
                projectSize
                primaryServiceLine
                frequency
                opportunityTypeMM
                pitchNotes
                estimatedBudget
                questions {
                  id
                  question
                }
                matchmakingOptions {
                  availabilityRange {
                    min
                    max
                    __typename
                  }
                  rateRange {
                    min
                    max
                    __typename
                  }
                  tags {
                    field
                    value
                    required
                    __typename
                  }
                  __typename
                }
                serviceLinesPlus
                requiredServiceLinesPlus
                estimatedStartDate
                estimatedEndDate
                estimatedTerm
                __typename
              }
            }
          `,
    variables: { id: id, input: data },
  });

export const getProposalEfforts = () =>
  axios.post(`${HOST_V2}`, {
    operationName: "getAmProposalsFormData",
    query: `query getAmProposalsFormData {
      getProjectSizes
      getServiceLines
    }`,
    variables: {},
  });

export const getServiceLinesPlus = () =>
  axios.post(`${HOST_V3}/graphql`, {
    query: `query {
              getServiceLinesPlus {
                id
                value
                paragraph
              }
            }`,
  });

export const getProposalTags = () =>
  axios.post(`${HOST_V2}`, {
    operationName: "getProposalTags",
    query: `query getProposalTags {
    getTags {
      industries {
        label
        keyName
        values
        __typename
      }
      software {
        label
        keyName
        values
        __typename
      }
      skills {
        label
        keyName
        values
        __typename
      }
      financialTasks {
        label
        keyName
        values
        __typename
      }
      states {
        label
        keyName
        values
        __typename
      }
      certificates {
        label
        keyName
        values
        __typename
      }
      languages {
        label
        keyName
        values
        __typename
      }
      haveATeam {
        label
        keyName
        values
        __typename
      }
      __typename
    }
  }
  `,
    variables: {},
  });

export const runMatch = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "updateRecommendations",
    query: `mutation updateRecommendations($input: UpdateRecommendationInput) {
      updateRecommendations(input: $input) {
       matchId
       recommendedFreeLancers {
                    flID
                    matchPercent
                    scores {
                      label
                      score
                      maxScore
                    }
                    relevantFields{
                        data
                        value
                        type
                    }
                    category
                    isNewExpert
                  }
        }
    }`,
    variables: { input: data },
  });

export const getFreelancerDetails = (legacyFreelancerId) =>
  axios.post(`${HOST_V3}/graphql`, {
    operationName: "getExpertByLegacyFreelancerId",
    query: `query getExpertByLegacyFreelancerId($legacyFreelancerId: Int!) {
    getExpertByLegacyFreelancerId(legacyFreelancerId: $legacyFreelancerId) {
      id
      suspensionReason
      requestProfileUpdate
      address {
        id
        city
        region
        __typename
      }
      certificates
      companyName
      education {
        id
        degree
        endDate
        major
        schoolName
        startDate
        __typename
      }
      externalProjects {
        id
        annualCompanyRevenueRange
        companyRevenueModel
        companySize
        companyType
        description
        endDate
        financialTags
        fundingSources
        hoursMonthly
        hoursTotal
        industryTags
        isInternational
        isRecurring
        onsite
        softwareTags
        startDate
        title
        __typename
      }
      financialTags
      hasCurrentClients
      industryTags
      languages
      legacyMetadata {
        defaultHourlyRate
        hourlyRate
        isOptedIn
        monthlyHoursAvailable
        primaryServiceLine
        applicationStatus
        isSuspended
        __typename
      }
      paroIncomeStatus
      personalSummary {
        id
        aboutYourself
        topFinancialSkills
        topIndustries
        topSoftwareSkills
        whyFreelanceWork
        __typename
      }
      publications {
        id
        publisher
        title
        url
        __typename
      }
      serviceLinesPlus
      softwareTags
      firmTags
      workExperiences {
        id
        description
        employer
        endDate
        financialTags
        industryTags
        isCurrentEmployer
        softwareTags
        startDate
        title
        __typename
      }
      workPreference {
        id
        billingCadence
        contactStylePreference
        contactTimePreference
        intendedMonthlyIncome
        isOpenToOnsite
        projectType
        totalFreelanceExperience
        weeklyWorkloadHours
        __typename
      }
      __typename
    }
  }`,
    variables: { legacyFreelancerId: legacyFreelancerId },
  });

export const getExpertsByLegacyFreelancerIds = (legacyFreelancerIds) =>
  axios.post(`${HOST_V3}/graphql`, {
    operationName: "getExpertsByLegacyFreelancerIds",
    query: `query getExpertsByLegacyFreelancerIds($legacyFreelancerIds: [Int!]) {
      getExpertsByLegacyFreelancerIds(legacyFreelancerIds: $legacyFreelancerIds) {
      legacyFreelancerId
      id
      suspensionReason
      requestProfileUpdate
      address {
        id
        city
        region
        __typename
      }
      certificates
      companyName
      education {
        id
        degree
        endDate
        major
        schoolName
        startDate
        __typename
      }
      externalProjects {
        id
        annualCompanyRevenueRange
        companyRevenueModel
        companySize
        companyType
        description
        endDate
        financialTags
        fundingSources
        hoursMonthly
        hoursTotal
        industryTags
        isInternational
        isRecurring
        onsite
        softwareTags
        startDate
        title
        __typename
      }
      financialTags
      hasCurrentClients
      industryTags
      languages
      legacyMetadata {
        defaultHourlyRate
        hourlyRate
        isOptedIn
        monthlyHoursAvailable
        primaryServiceLine
        applicationStatus
        isSuspended
        __typename
      }
      paroIncomeStatus
      personalSummary {
        id
        aboutYourself
        topFinancialSkills
        topIndustries
        topSoftwareSkills
        whyFreelanceWork
        __typename
      }
      publications {
        id
        publisher
        title
        url
        __typename
      }
      serviceLinesPlus
      softwareTags
      firmTags
      workExperiences {
        id
        description
        employer
        endDate
        financialTags
        industryTags
        isCurrentEmployer
        softwareTags
        startDate
        title
        __typename
      }
      workPreference {
        id
        billingCadence
        contactStylePreference
        contactTimePreference
        intendedMonthlyIncome
        isOpenToOnsite
        projectType
        totalFreelanceExperience
        weeklyWorkloadHours
        __typename
      }
      __typename
    }
  }`,
    variables: { legacyFreelancerIds: legacyFreelancerIds },
  });

export const getFreelancerUserInfo = (legacyFreelancerId) =>
  axios.post(`${HOST_V3}/graphql`, {
    operationName: "getUser",
    query: `query getUser($legacyFreelancerId: Int!) {
      getUserInfo(id: $legacyFreelancerId) {
        id
        email
        firstName
        imageUrl
        lastName
        phone
        __typename
      }
    }
    `,
    variables: { legacyFreelancerId: legacyFreelancerId },
  });

export const submitRecommendations = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "amSubmitFeedback",
    query: `mutation amSubmitFeedback($input: MatchFeedbackInput) {
      submitMatchFeedback(input: $input) {
       message
        }
    }`,
    variables: { input: data },
  });

export const addToCommunicationHub = (data) =>
  axios.post(`${HOST_V3}/graphql`, {
    operationName: "createProposedProjectCommunicationService",
    query: `mutation createProposedProjectCommunicationService(
        $input: createProposedProjectCommServiceInput!
      ) {
        createProposedProjectCommunicationService(input: $input) {
          id
          freelancer {
            id
          }
          proposal {
            id
          }
          proposedProjectStage
        }
      }
      `,
    variables: { input: data },
  });

export const sendEmails = (data) =>
  axios.post(`${HOST_V2}`, {
    operationName: "amCreateProposedProject",
    query: `mutation amCreateProposedProject($input: createProposedProjectParams!) {
            createProposedProject(input: $input) {
              id
              freelancer {
                id
                __typename
              }
              proposal {
                id
                __typename
              }
              proposedProjectStage
              __typename
            }
          }
          `,
    variables: { input: data },
  });

export const getVettingNotes = (freelancerId) =>
  axios.post(`${HOST_V3}/graphql`, {
    operationName: "getVettingNotes",
    query: `query getVettingNotes($freelancerId: Int!) {
      getFreelancerCandidateNotes(freelancerId: $freelancerId) {
        id
        accountManager {
          id
          name
          email
          __typename
        }
        note
        timestamp
        stageId
        editable
        __typename
      }
    }
    `,
    variables: { freelancerId: freelancerId },
  });

export const getParoProjects = (freelancerId) =>
  axios.post(`${HOST_V3}/graphql`, {
    operationName: "getParoProjects",
    query: `query getParoProjects($legacyFreelancerId: Int!) {
      getParoProjects(freelancerId: $legacyFreelancerId) {
        projectScopeId
        id
        softwareTags {
          id
          value
          __typename
        }
        financialTags {
          id
          value
          __typename
        }
        name
        description
        startDate
        endDate
        frequency
        size {
          id
          value
          __typename
        }
        onsite
        client {
          id
          businessModel
          fundingSources
          companySize {
            id
            value
            __typename
          }
          industryTags {
            id
            value
            __typename
          }
          international
          annualRevenueRange {
            id
            value
            __typename
          }
          entityType {
            id
            value
            __typename
          }
          __typename
        }
        __typename
      }
    }
    `,
    variables: { legacyFreelancerId: freelancerId },
  });

export const getFreelancerProjects = (freelancerId) =>
  axios.post(`${HOST_V3}/graphql`, {
    operationName: "getFreelancerProjects",
    query: `query getFreelancerProjects($legacyFreelancerId: Int!) {
        getFreelancerProjects(freelancerId: $legacyFreelancerId) {
          billType
          clientName
          startDate
          projectName
          payRate
          frequency
          status
          __typename
        }
      }
      `,
    variables: { legacyFreelancerId: freelancerId },
  });

export const getAvailability = (freelancerId) =>
  axios.post(`${HOST_V3}/graphql`, {
    operationName: "getAvailability",
    query: `query getAvailability($legacyFreelancerId: Int!) {
        getFreelancerProfile(freelancerId: $legacyFreelancerId) {
          estimatedPotentialHours
          currentMonthClientCount
          currentMonthHoursCount
          currentMonthProjectCount
          previousMonthClientCount
          previousMonthHoursCount
          previousMonthProjectCount
          totalProposals
          totalProposalClients
          projectWinRate
          availableHours
          id
          name
          currentProjectedHours
          freelancerHours
          serviceLine
          state
          clientBillRate
          __typename
        }
      }
      `,
    variables: { legacyFreelancerId: freelancerId },
  });

export const submitFreelancerFeedback = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "amSubmitFreelancerFeedback",
    query: `mutation amSubmitFreelancerFeedback($input: FreelancerFeedbackInput) {
      submitFreelancerFeedback(input: $input) {__typename}
    }`,
    variables: { input: data },
  });

export const reportFreelancer = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "amReportFreelancer",
    query: `mutation amReportFreelancer($input: ReportFreelancerInput) {
       reportFreelancer(input: $input) {__typename}
    }`,
    variables: { input: data },
  });

export const removeFreelancer = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "amRemoveFreelancer",
    query: `mutation amRemoveFreelancer($input: RemoveFromCartFeedbackInput) {
       removeFromCartFeedback(input: $input) {__typename}
    }`,
    variables: { input: data },
  });

export const submitAppFeedback = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "amSubmitAppFeedback",
    query: `mutation amSubmitAppFeedback($input: AppFeedbackInput) {
      submitAppFeedback(input: $input) {__typename}
    }`,
    variables: { input: data },
  });

export const saveFLProfileSettings = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "amSaveFLProfileSettings",
    query: `mutation amSaveFLProfileSettings($input: CustomizeFLProfileInput){
      saveFLProfileSettings(input: $input) {
        aeId
        fields
        templateName
        description
        selected
      }
    }`,
    variables: { input: data },
  });

export const getFLFullProfileViews = (aeId) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "getFLProfileSettings",
    query: `query getFLProfileSettings($aeId: Int){
      getFLProfileSettings(aeId: $aeId){
        aeId
        fields
        templateName
        description
        selected
      }
    }`,
    variables: { aeId: aeId },
  });

export const updateTemplateSelection = (aeId, templateName, selected) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "updateTemplateSelection",
    query: `mutation updateTemplateSelection($aeId: Int, $templateName: String, $selected: Boolean){
      updateTemplateSelection(aeId: $aeId, templateName: $templateName, selected: $selected) {
        message
      }
    }`,
    variables: { aeId: aeId, templateName: templateName, selected: selected },
  });

export const saveProposalRematchReason = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "amSaveProposalRematchReason",
    query: `mutation amSaveProposalRematchReason($input: ProposalRematchInput) {
      saveProposalRematchReason(input: $input) {__typename}
    }`,
    variables: { input: data },
  });

export const getProposalMatchCount = (proposalId, userId) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "amGetProposalMatchCount",
    query: `query amGetProposalMatchCount($proposalId: Int, $userId: Int){
      getProposalMatchCount(proposalId: $proposalId, userId: $userId) {
        proposalId
        userId
        matchCount
      }
    }`,
    variables: {
      proposalId: proposalId,
      userId: userId,
    },
  });

export const updateProposalMatchCount = (
  proposalId,
  userId,
  updateMatchCount // "reset" | "increment"
) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "amUpdateProposalMatchCount",
    query: `mutation amUpdateProposalMatchCount($input: ProposalMatchCountInput) {
      updateProposalMatchCount(input: $input) {__typename}
    }`,
    variables: {
      input: {
        proposalId: proposalId,
        userId: userId,
        updateMatchCount: updateMatchCount,
      },
    },
  });

export const addToCartFn = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "addToCart",
    query: `mutation addToCart($input: AddToCartInput) {
      addToCart(input: $input) {
        matchId
        recommendedFreeLancers {
          flID
          matchPercent
          category
        }
      }
    }`,
    variables: { input: data },
  });

export const addSingleFlToCartFn = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "addSingleFlToCart",
    query: `mutation addSingleFlToCart($input: AddToCartInput) {
      addSingleFlToCart(input: $input) {
        matchId
        recommendedFreeLancers {
          flID
          matchPercent
          category
        }
      }
    }`,
    variables: { input: data },
  });

export const updateCartCheckout = (proposalId, userId, checkout) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "updateCartCheckout",
    query: `mutation updateCartCheckout($proposalId: Int!, $userId: Int!, $checkout: Boolean) {
      updateCartCheckout(proposalId: $proposalId, userId: $userId, checkout: $checkout){
        message
      }
    }`,
    variables: {
      proposalId: proposalId,
      userId: userId,
      checkout: checkout,
    },
  });

export const getCartDetails = (proposalId, userId) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "getCartDetails",
    query: `query getCartDetails($proposalId: Int!, $userId: Int!) {
      getCartDetails(proposalId: $proposalId, userId: $userId){
        matchId
        recommendedFreeLancers {
          flID
          matchPercent
          category
          scores {
            label
            score
            maxScore
          }
        }
      }
    }`,
    variables: { proposalId: proposalId, userId: userId },
  });

export const removeFromCartFn = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "removeFromCart",
    query: `mutation removeFromCart($input: RemoveFromCartInput) {
      removeFromCart(input: $input) {
        matchId
        recommendedFreeLancers {
          flID
          matchPercent
          category
        }
      }
    }`,
    variables: { input: data },
  });

export const sectionRecommendation = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "sectionRecommendation",
    query: `mutation sectionRecommendation($input: SectionRecommendationInput) {
      sectionRecommendation(input: $input) {
       message
      }
    }`,
    variables: { input: data },
  });

export const getFreelancerProfiles = (queryOptions) =>
  axios.post(`${HOST_V2}`, {
    operationName: "getFreelancerProfiles",
    query: `query getFreelancerProfiles($queryOptions: FPQueryOptions) {
        getFreelancerProfiles(queryOptions: $queryOptions) {
          id
          name
          currentProjectedHours
          estimatedPotentialHours
          freelancerHours
          serviceLine
          state
          currentMonthClientCount
          previousMonthClientCount
          totalProposalClients
          currentMonthHoursCount
          previousMonthHoursCount
          availableHours
          clientBillRate
          freelancerStatus
          __typename
        }
      }
      `,
    variables: queryOptions,
  });

export const getParo4cpaFlIds = () =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "getParo4cpaFls",
    query: `query getParo4cpaFls {
      getParo4cpaFls {
        freelancer_id
      }
    }`,
  });

export const getFreelancersFeedback = (matchIds) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "getFreelancersFeedback",
    query: `query getFreelancersFeedback($matchIds: [Int]) {
      getFreelancersFeedback(matchIds: $matchIds){
        freelancerId
        feedback
      }
    }`,
    variables: { matchIds: matchIds },
  });

export const toggleJobBoard = (id, onJobBoard) =>
  axios.post(`${HOST_V2}`, {
    operationName: "toggleJobBoard",
    query: `mutation toggleJobBoard($id: Int!, $onJobBoard: Boolean) {
              toggleProposalOnJobBoard(id: $id, onJobBoard: $onJobBoard) {
                id
                __typename
              }
            }
          `,
    variables: { id: +id, onJobBoard: onJobBoard },
  });
export const getProposalsMatchCount = (proposalIds, userId) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "getProposalsMatchCount",
    query: `query getProposalsMatchCount($proposalIds: [Int], $userId: Int) {
      getProposalsMatchCount(proposalIds: $proposalIds, userId: $userId){
        proposalId
        matchCount
      }
    }`,
    variables: { proposalIds: proposalIds, userId: userId },
  });

export const saveFaFResult = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "saveFaFResult",
    query: `mutation saveFaFResult($input: FaFInput) {
      saveFaFResult(input: $input) {
        message
      }
    }
    `,
    variables: {
      input: data,
    },
  });

export const getReferenceFreelancersPool = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "getReferenceFreelancersPool",
    query: `query getReferenceFreelancersPool($input: UpdateRecommendationInput) {
      getReferenceFreelancersPool(input: $input) {
        totalFreelancers
      }
    }
    `,
    variables: {
      input: data,
    },
  });

export const addFindFreelancersToCartFn = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "addFindFreelancersToCart",
    query: `mutation addFindFreelancersToCart($input: AddToCartInput) {
    addFindFreelancersToCart(input: $input) {
      message
    }
  }`,
    variables: { input: data },
  });

export const getFindFreelancersCartFn = (proposalId, userId) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "getFindFreelancersCart",
    query: `query getFindFreelancersCart($proposalId: Int!, $userId: Int!) {
    getFindFreelancersCart(proposalId: $proposalId, userId: $userId){
      freelancerId
      matchPercent
      jobboardFields {
        data
        value
        type
      }
      qualityScorecard {
        label
        maxScore
        score
      }
    }
  }`,
    variables: { proposalId: proposalId, userId: userId },
  });

export const removeFindFlFromCartFn = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "removeFindFlFromCart",
    query: `mutation removeFindFlFromCart($proposalId: Int!, $userId: Int!, $freelancerId: Int!) {
    removeFindFlFromCart(proposalId: $proposalId, userId: $userId, freelancerId:$freelancerId){
      message
    }
  }`,
    variables: data,
  });

export const getFreelancerProfilesByIdsFn = (freelancerIds) =>
  axios.post(`${HOST_V3}/graphql`, {
    operationName: "getFreelancerProfilesByIds",
    query: `query getFreelancerProfilesByIds($freelancerIds: [Int]!) {
      getFreelancerProfilesByIds(freelancerIds: $freelancerIds) {
        id
        name
        serviceLine
        imageUrl
      }
    }
    `,
    variables: { freelancerIds: freelancerIds },
  });

export const getGpt3Suggestion = (data) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "getGpt3Suggestion",
    query: `query getGpt3Suggestion($input: Gpt3SuggestionInput) {
      getGpt3Suggestion(input: $input){
        Indicator
        Industry
        Notes
        Output
        Segment
      }
    }`,
    variables: { input: data },
  });

export const sendDefaultEmail = (proposalId, freelancerIds) =>
  axios.post(`${HOST_V3}/graphql`, {
    operationName: "sendProposedEmailsBatch",
    query: `mutation sendProposedEmailsBatch($proposalId: Int, $freelancerIds: [Int]) {
      sendProposedEmailsBatch(proposalId: $proposalId, freelancerIds: $freelancerIds) {
        freelancerId
        name
        email
        status
        source
        frequency
        expiresIn
        expiresAt
        rejectedAt
        contactedAt
        emailsInfo {
          lastEmailSentAt
          emailsSent
        }
      }
    }`,
    variables: {
      proposalId: proposalId,
      freelancerIds: freelancerIds,
    },
  });

export const updateProposedProjectStageCommunicationServiceFn = (input) =>
  axios.post(`${HOST_V3}/graphql`, {
    operationName: "updateProposedProjectStageCommunicationService",
    query: `mutation updateProposedProjectStageCommunicationService($input: proposedStageUpdateCommunicationServiceInput!) {
      updateProposedProjectStageCommunicationService(input: $input) {
        message
      }
    }
    `,
    variables: {
      input: input,
    },
  });

export const getAllQuestionsTemplates = (userId) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "getAllQuestionsTemplates",
    query: `query getAllQuestionsTemplates($userId: Int!) {
      getAllQuestionsTemplates(userId: $userId){
        questions
        userId
        templateId
        templateName
      }
    }`,
    variables: { userId: userId },
  });

export const createQuestionsTemplate = (input) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "createQuestionsTemplate",
    query: `mutation createQuestionsTemplate($input: AddQuestionsTemplateInput!) {
      createQuestionsTemplate(input: $input) {
        message
      }
    }
    `,
    variables: {
      input: input,
    },
  });

export const deleteQuestionsTemplate = (input) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "deleteQuestionsTemplate",
    query: `mutation deleteQuestionsTemplate($input: DeleteQuestionsTemplateInput!) {
      deleteQuestionsTemplate(input: $input) {
        message
      }
    }
    `,
    variables: {
      input: input,
    },
  });

export const updateQuestionsTemplate = (input) =>
  axios.post(`${HOST_MM_V3}`, {
    operationName: "updateQuestionsTemplate",
    query: `mutation updateQuestionsTemplate($input: UpdateQuestionsTemplateInput!) {
      updateQuestionsTemplate(input: $input) {
        message
      }
    }
    `,
    variables: {
      input: input,
    },
  });

export const getRenewalProposals = (salesRepId) =>
  axios.post(`${HOST_V2}`, {
    operationName: "getRenewalProposals",
    query: `query getRenewalProposals($salesRepId: Int) {
        getRenewalProposals(salesRepId: $salesRepId) {
          id
          proposalId
          proposal {
            name
          }
          projectId
          project {
            name
            billRateType
            frequency
          }
          clientId
          client {
            name
          }
          freelancerId
          freelancer {
            name
          }
          endDate
          startDate
          salesRepId
          rejectedReason
          rejectedByUserId
          rejectedAt
          aeEmailSentAt
          expertEmailSentAt
          clientEmailSentAt
          __typename
        }
      }`,
    variables: { salesRepId: salesRepId },
  });

export const stopRenewal = ({
  renewalProposalId,
  rejectedReason,
  rejectedByUserId,
}) =>
  axios.post(`${HOST_V2}`, {
    operationName: "stopRenewal",
    query: `mutation stopRenewal($rejectedByUserId: Int!, $rejectedReason: String!, $renewalProposalId: Int!) {
      stopRenewal(renewalProposalId: $renewalProposalId, rejectedReason: $rejectedReason, rejectedByUserId: $rejectedByUserId)
    }
    `,
    variables: {
      renewalProposalId: renewalProposalId,
      rejectedReason: rejectedReason,
      rejectedByUserId: rejectedByUserId,
    },
  });

export const getAllFreelancerRatingsAndReviews = (freelancerId) =>
  axios.post(`${HOST_V3}/graphql`, {
    operationName: "getAllFreelancerRatingsAndReviews",
    query: `query getAllFreelancerRatingsAndReviews($freelancerId: Int!) {
      getAllFreelancerRatingsAndReviews(freelancerId: $freelancerId) {
        freelancerId
        overallRating
        communicationRating
        timelinessRating
        professionalismRating
        executionOfDeliverablesRating
        review
      }
    }`,
    variables: { freelancerId: freelancerId },
  });

export const getBlacklistedExperts = () =>
  axios.post(`${HOST_V2}`, {
    operationName: "getBlacklistedExperts",
    query: `query getBlacklistedExperts {
      getBlacklistedExperts {
        expertId
      }
    }`,
  });
